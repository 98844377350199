import React, { useCallback, useContext, useEffect, useState } from 'react';

import { DisplayCoordinates } from '../types/coordinates';
import { useConfig } from '../services/Config';
import { DistanceDistance } from '../types/units';

export type State = {
  coordinates: any;
  units: string | null;
  setDisplayCoordinates: any;
  setDisplayUnits: any;
};
// make a new context
const PreferencesContext = React.createContext<State | undefined>(undefined);

export const PreferencesContextProvider = props => {
  const { getConfig } = useConfig();
  const defCoord = getConfig('DEFAULT_COORDINATES');
  const defUnits = getConfig('DEFAULT_UNITS');
  const [coordinates, setCoordinates] = useState<DisplayCoordinates>(defCoord);
  const [units, setUnits] = useState<DistanceDistance>(defUnits);

  // DEV NOTE: not sure if this is the best way to do, this, maybe this context is OBE we
  // and we just use the the useConfig...tbd
  useEffect(() => {
    setCoordinates(getConfig('DEFAULT_COORDINATES'));
  }, [defCoord, getConfig]);

  useEffect(() => {
    setUnits(getConfig('DEFAULT_UNITS'));
  }, [defUnits, getConfig]);

  const setDisplayCoordinates = useCallback(coord => {
    setCoordinates(coord);
  }, []);

  const setDisplayUnits = useCallback(unit => {
    setUnits(unit);
  }, []);

  const contextValue = {
    coordinates: coordinates,
    units: units,
    setDisplayCoordinates,
    setDisplayUnits,
  };

  return <PreferencesContext.Provider value={contextValue}>{props.children}</PreferencesContext.Provider>;
};

export const usePreferencesContextState = (): State | undefined => {
  const context = useContext(PreferencesContext);
  if (!context) {
    throw new Error('must be used within PreferencesContextProvider');
  }
  return context;
};

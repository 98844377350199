import React from 'react';
import './Banner.css';
import styled from 'styled-components/macro';

export function Banner({ securityBanner, securityColor }) {
  // @ts-ignore
  return <Wrapper className={securityColor}>{securityBanner}</Wrapper>;
}

const Wrapper = styled.div`
  // background: green;
  // color: white;
  z-index: 10000;
  // display: flex;
  // top: 0;
  text-align: center;
  display: block;
`;

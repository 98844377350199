import { PayloadAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { RequestStatus } from 'types/RequestStatus';
import { getIntelDataById } from './IntelDataSlice';

type CollectionRequest = { id: string; geometry: any; properties: any; type: string };
// export interface CollectionRequestState {
//   byId: any;
//   selected: string | null;
//   showAll: boolean;
// }

const collectionRequestAdapter = createEntityAdapter<CollectionRequest>({
  selectId: cr => cr.id,
  // sortComparer: (a, b) => a.id.localeCompare(b.id),
  sortComparer: (a, b) => {
    try {
      const bTime = Date.parse(b.properties['Created Time']).valueOf();
      const aTime = Date.parse(a.properties['Created Time']).valueOf();
      const delta = bTime - aTime;
      if (isNaN(delta)) {
        return 0;
      }
      return delta;
    } catch {
      return 0;
    }
  },
});

// const initialState: CollectionRequestState = {
//   byId: null,
//   selected: null,
//   showAll: true,
// };

const emptyFeatureCollection = { type: 'FeatureCollection', features: [] as any[] };

const getStatus = collectionRequest => {
  if (!collectionRequest && !collectionRequest.properties?.['SGS Request Status']) {
    return null;
  }
  return collectionRequest?.properties['SGS Request Status']?.toUpperCase();
};

const processRawFeatures = rawCollectionRequests => {
  const collectionRequests: any[] = [];
  const ipta: any[] = [];
  // const crGeoJsonFeatures: any[] = [];
  const submitted: string[] = [];
  const dismissed: string[] = [];
  const deleted: string[] = [];
  //state.selectedId = null;
  // TODO 20231205 -- still not sure how to best ipta...own slice?
  //const iptaProposedTasks = JSON.parse(JSON.stringify(emptyFeatureCollection));
  // const collectionRequestGeoJson = JSON.parse(JSON.stringify(emptyFeatureCollection));
  rawCollectionRequests.forEach((entry: any) => {
    if (entry?.geometry?.type === 'Point') {
      console.log(`DP CR ${JSON.stringify(entry)}`);
      collectionRequests.push(entry);
      // collectionRequestGeoJson.features.push(entry);
      if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DISMISSED') {
        dismissed.push(entry.id);
      } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'SUBMITTED') {
        submitted.push(entry.id);
      } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DELETED') {
        deleted.push(entry.id);
      }
    } else if (entry?.properties['IPTA Proposed'] === true) {
      ipta.push(entry);
      //  iptaProposedTasks.features.push(ipta);
    }
  });
  // DEV NOTE: could I just use the entities here? Not sure
  //  state.collectionRequestGeoJson.features = collectionRequests;
  //  iptaProposedTasks.features = ipta;

  // collectionRequestAdapter.setAll(state, collectionRequests);
  // state.status = RequestStatus.SUCCESS;
  // state.updated = new Date();

  const mr = {
    collectionRequests,
    dismissed,
    deleted,
    submitted,
    iptaProposedTasks: ipta,
    //collectionRequestGeoJson,
  };
  return mr;
};

const processStateSGSStatus = collectionRequests => {
  const dismissed: string[] = [];
  const deleted: string[] = [];
  const submitted: string[] = [];
  collectionRequests.forEach((cr: any) => {
    const st = getStatus(cr);
    if ('DISMISSED' === st) {
      dismissed.push(cr.id);
    } else if ('DELETED' === st) {
      deleted.push(cr.id);
    } else if ('SUBMITTED' === st) {
      submitted.push(cr.id);
    }
  });
  return { dismissed, deleted, submitted };
};

export const collectionRequestSlice = createSlice({
  name: 'collectionRequests',
  initialState: collectionRequestAdapter.getInitialState({
    status: RequestStatus.NOT_LOADED,
    selectedId: null,
    showAll: true,
    submitted: [] as string[],
    dismissed: [] as string[],
    deleted: [] as string[],
    iptaProposedTasks: [] as string[],
    showDismissed: false,
    showDeleted: false,
    //collectionRequestGeoJson: { ...emptyFeatureCollection },
    updated: new Date(),
  }),
  reducers: {
    addCollectionRequest: (state, action: PayloadAction<CollectionRequest>) => {
      collectionRequestAdapter.addOne(state, action.payload);
      switch (getStatus(action.payload)) {
        case 'DISMISSED':
          const dismissed = [...state.dismissed];
          dismissed.push(action.payload.id);
          state.dismissed = dismissed;
          break;
        case 'SUBMITTED':
          const submitted = [...state.submitted];
          submitted.push(action.payload.id);
          state.submitted = submitted;
          break;
        case 'DELETED':
          const deleted = [...state.submitted];
          deleted.push(action.payload.id);
          state.submitted = deleted;
          break;
      }
    },
    setCollectionRequests: (state, action: PayloadAction<CollectionRequest[]>) => {
      const { collectionRequests, dismissed, deleted, submitted, iptaProposedTasks } = processRawFeatures(
        action.payload,
      );
      collectionRequestAdapter.setAll(state, collectionRequests);
      // const { dismissed, deleted, submitted } = processStateSGSStatus(action.payload);
      state.dismissed = dismissed;
      state.deleted = deleted;
      state.submitted = submitted;
      state.iptaProposedTasks = iptaProposedTasks;
      state.selectedId = null;
      state.status = RequestStatus.SUCCESS;
      state.updated = new Date();
    },
    clearCollectionRequests: state => {
      state.status = RequestStatus.NOT_LOADED;
      state.entities = {};
      state.ids = [];
      state.selectedId = null;
      state.submitted = [];
      state.dismissed = [];
      state.deleted = [];
      state.iptaProposedTasks = [];
      //state.collectionRequestGeoJson = { ...emptyFeatureCollection };
      state.updated = new Date();
    },
    showAllData: state => {
      state.showAll = true;
    },
    hideAllData: state => {
      state.showAll = false;
    },
    showDismissed: state => {
      state.showDismissed = true;
    },
    hideDismissed: state => {
      state.showDismissed = false;
    },
    showDeleted: state => {
      state.showDeleted = true;
    },
    hideDeleted: state => {
      state.showDeleted = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllCollectionRequestData.fulfilled, (state, { payload }) => {
      if (payload) {
        collectionRequestAdapter.setAll(state, payload.collectionRequests);
        state.deleted = payload.deleted;
        state.dismissed = payload.dismissed;
        state.submitted = payload.submitted;
        state.iptaProposedTasks = payload.iptaProposedTasks;
        //state.collectionRequestGeoJson = payload.collectionRequestGeoJson;
        state.status = RequestStatus.SUCCESS;
        state.updated = new Date();
      }

      // if (payload) state.data.features = payload;
      // if (payload) {
      //   const collectionRequests: any[] = [];
      //   const ipta: any[] = [];
      //   // const crGeoJsonFeatures: any[] = [];
      //   state.deleted = [];
      //   state.dismissed = [];
      //   state.deleted = [];
      //   state.selectedId = null;
      //   // TODO 20231205 -- still not sure how to best ipta...own slice?
      //   state.iptaProposedTasks = { ...emptyFeatureCollection };
      //   payload.features.forEach((entry: any) => {
      //     if (entry?.geometry?.type === 'Point') {
      //       collectionRequests.push(entry);
      //     }
      //     if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DISMISSED') {
      //       state.dismissed.push(entry.id);
      //     } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'SUBMITTED') {
      //       state.submitted.push(entry.id);
      //     } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DELETED') {
      //       state.deleted.push(entry.id);
      //     } else if (entry?.properties['IPTA Proposed'] === true) {
      //       ipta.push(entry);
      //     }
      //   });
      //   // DEV NOTE: could I just use the entities here? Not sure
      //   state.collectionRequestGeoJson.features = collectionRequests;
      //   state.iptaProposedTasks.features = ipta;

      //   collectionRequestAdapter.setAll(state, collectionRequests);
      //   state.status = RequestStatus.SUCCESS;
      //   state.updated = new Date();
      // }
    });
    builder.addCase(getAllCollectionRequestData.rejected, (state, action) => {
      // if (payload) state.data.features = payload;
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
  },
});

const getData = async (requestUrl: string) => {
  const client = axios.create();
  axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
  });
  const response = await client.get(requestUrl);
  return response.data;
};

/**
 * Thunk to retrieve all intel data
 */
export const getAllCollectionRequestData = createAsyncThunk(
  'collectionRequests/getAllCollectionRequestData',
  async (arg: any, thunkAPI) => {
    console.log(`getAllCollectionRequestData url ${arg}`);
    try {
      const response = await getData(arg);

      if (response) {
        const mr = processRawFeatures(response.features);

        // const collectionRequests: any[] = [];
        // const ipta: any[] = [];
        // // const crGeoJsonFeatures: any[] = [];
        // const submitted: string[] = [];
        // const dismissed: string[] = [];
        // const deleted: string[] = [];
        // //state.selectedId = null;
        // // TODO 20231205 -- still not sure how to best ipta...own slice?
        // //const iptaProposedTasks = JSON.parse(JSON.stringify(emptyFeatureCollection));
        // // const collectionRequestGeoJson = JSON.parse(JSON.stringify(emptyFeatureCollection));
        // response.features.forEach((entry: any) => {
        //   if (entry?.geometry?.type === 'Point') {
        //     console.log(`DP CR ${JSON.stringify(entry)}`);
        //     collectionRequests.push(entry);
        //     // collectionRequestGeoJson.features.push(entry);
        //     if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DISMISSED') {
        //       dismissed.push(entry.id);
        //     } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'SUBMITTED') {
        //       submitted.push(entry.id);
        //     } else if (entry?.properties['SGS Request Status']?.toUpperCase() === 'DELETED') {
        //       deleted.push(entry.id);
        //     }
        //   } else if (entry?.properties['IPTA Proposed'] === true) {
        //     ipta.push(entry);
        //     //  iptaProposedTasks.features.push(ipta);
        //   }
        // });
        // // DEV NOTE: could I just use the entities here? Not sure
        // //  state.collectionRequestGeoJson.features = collectionRequests;
        // //  iptaProposedTasks.features = ipta;

        // // collectionRequestAdapter.setAll(state, collectionRequests);
        // // state.status = RequestStatus.SUCCESS;
        // // state.updated = new Date();

        // const mr = {
        //   collectionRequests,
        //   dismissed,
        //   deleted,
        //   submitted,
        //   iptaProposedTasks: ipta,
        //   //collectionRequestGeoJson,
        // };

        // const mr = [...response.features].filter((item) => Object.hasOwn(item, "SGS Request Status"));
        // Just collect the collection requests
        // const mr =response.features.filter(entry => {
        //   if ((entry?.geometry?.type === "Point")) {
        //     return true;
        //   }
        // });
        // const mr = response.flatMap(entry => {
        //   return entry.payload.map(x => {
        //     return { id: x.data.properties.id, opsBoxId: entry.opsBoxId, data: x.data};
        //   });
        // });
        // intelDataAdapter.setAll(state, payload);
        // state.selectedId = null;
        // //TEMP
        // const mr = response.payload.map(p => p.data);
        console.log(`getAllCollectionRequestData ${mr}`);
        return mr;
      }
      // return response;
    } catch (ex) {
      //@ts-ignore
      console.error(`Error occurred calling '${arg}', error=${ex}`);
      return null;
    }
  },
);

export const { addCollectionRequest, setCollectionRequests, clearCollectionRequests, showAllData, hideAllData } =
  collectionRequestSlice.actions;

export const {
  selectById: selectCollectionRequestsById,
  selectIds: selectCollectionRequestIds,
  selectEntities: selectCollectionRequestEntities,
  selectAll: selectAllCollectionRequests,
  selectTotal: selectTotalCollectionRequests,
} = collectionRequestAdapter.getSelectors((state: any) => state.collectionRequests);

const NON_ACTIVE_STATUS = ['DELETED', 'DISMISSED'];

export const deletedState = state => state.collectionRequests.deleted;
export const dismissedState = state => state.collectionRequests.dismissed;
export const showDismissedState = state => state.collectionRequests.showDismissed;
export const showDeletedState = state => state.collectionRequests.showDeleted;
const iptaProposedTasksState = state => state.collectionRequests.iptaProposedTasks;
//const collectionRequestGeoJsonState = state => state.collectionRequests.collectionRequestGeoJson;
export const selectDeleted = createSelector([state => state.collectionRequests], s => {
  const deleted = s.deleted.map(i => s.entities[i]);
  const data = { requests: deleted, status: s.status, updated: s.updated };
  return data;
});
export const selectDismissed = createSelector([state => state.collectionRequests], s => {
  const dismissed = s.dismissed.map(i => s.entities[i]);
  const data = { requests: dismissed, status: s.status, updated: s.updated };
  return data;
});
export const selectSubmitted = createSelector([state => state.collectionRequests], s => {
  const submitted = s.submitted.map(i => s.entities[i]);
  const data = { requests: submitted, status: s.status, updated: s.updated };
  return data;
});
//export const selectIptaProposedTasks = createSelector([iptaProposedTasksState], x => x);
export const selectCollectionRequestGeoJson = createSelector([state => state.collectionRequests], x => {
  const data = { requests: x.collectionRequestGeoJson, status: x.status, updated: x.updated };
  return data;
});

export const selectCollectionRequests = createSelector(
  [state => state.collectionRequests, state => selectAllCollectionRequests(state)],
  (x, all) => {
    const data = { requests: all as any, status: x.status, updated: x.updated };
    return data;
  },
);

/**
 * Not submitted, deleted, or dismissed
 */
export const selectRequestedCollectionRequests = createSelector(
  [state => state.collectionRequests, state => selectAllCollectionRequests(state)],
  (x, all) => {
    const filtered = all.filter(entry => {
      if (x.submitted.includes(entry.id)) return false;
      if (x.deleted.includes(entry.id)) return false;
      if (x.dismissed.includes(entry.id)) return false;
      return true;
    });
    const data = { requests: filtered as any, status: x.status, updated: x.updated };
    return data;
  },
);

/**
 * Not deleted, or dismissed
 */
export const selectSubmittedAndRequestedCollectionRequests = createSelector(
  [state => state.collectionRequests, state => selectAllCollectionRequests(state)],
  (x, all) => {
    const filtered = all.filter(entry => {
      if (x.deleted.includes(entry.id)) return false;
      if (x.dismissed.includes(entry.id)) return false;
      return true;
    });
    const data = { requests: filtered as any, status: x.status, updated: x.updated };
    return data;
  },
);

export const selectIptaProposedTasks = createSelector([state => state.collectionRequests], s => {
  const ipta = s.iptaProposedTasks;
  const data = { requests: ipta, status: s.status, updated: s.updated };
  return data;
});

// export const selectcollectionRequestGeoJson = createSelector([collectionRequestGeoJsonState], x => {
//   return x;
//  });

// { requests: collectionRequestGeoJson, status: RequestStatus.SUCCESS, updated: new Date() }

/**
 *
 * @returns collection requests that are NOT of status of DELETED or DISMISSED
 */
export const selectActiveCollectionRequests = createSelector([state => selectAllCollectionRequests(state)], requests =>
  requests.filter(data => {
    const status = data.properties['SGS Request Status'];
    return !NON_ACTIVE_STATUS.includes(status);
  }),
);

export const selectVisibleCollectionRequests = createSelector(
  [state => selectAllCollectionRequests(state), showDismissedState, showDeletedState],
  (collectionRequests, showDismissed: boolean, showDeleted: boolean) => {
    const filterData = collectionRequests.filter(data => {
      let filter = false;
      const sgsStatus = data.properties['SGS Request Status'];
      if (!showDismissed) {
        filter = sgsStatus === 'DISMISSED';
        console.log(`DP DISMISSED ${filter}`);
      }
      if (!showDeleted) {
        filter = filter || sgsStatus === 'DELETED';
      }
      return !filter;
    });
    return filterData;
  },
);

export default collectionRequestSlice.reducer;

/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

//import { initStore } from 'store/configureStore';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { AppContextProvider } from './context/app-context';
import './index.css';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';
import { PreferencesContextProvider } from './context/preferences-context';
import { WebSocketContextProvider } from './context/ws-context';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { store } from './store/configureStore';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Inter', {});
//
// // When Inter is loaded, add a font-family using Inter to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const SpecialModalBackground = styled(BaseModalBackground)`
  z-index: 3000;
`;
const root = createRoot(MOUNT_NODE);
//const store = initStore();

root.render(
  <ModalProvider backgroundComponent={SpecialModalBackground}>
    <Provider store={store}>
      <ThemeProvider>
        <HelmetProvider>
          <StrictMode>
            <PreferencesContextProvider>
              <AppContextProvider>
                <WebSocketContextProvider>
                  <App />
                </WebSocketContextProvider>
              </AppContextProvider>
            </PreferencesContextProvider>
          </StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </Provider>
  </ModalProvider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { getTheme, getThemeFromStorage, isSystemDark } from './utils';
import { themes } from './themes';
import { useEffect, useState } from 'react';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  const [currentTheme, setTheme] = useState<string>('dark');

  useEffect(() => {
    //If the user changes a runtime config, it is written to local storage.
    //This will listen to local storage changes and adjust the theme if
    //there is a storage change.
    window.addEventListener('storage', () => {
      let newTheme = getThemeFromStorage() || getTheme() || 'dark';
      if (newTheme === 'system') newTheme = isSystemDark ? 'dark' : 'light';
      setTheme(newTheme);
    });
  }, []);

  //Themes are read only on page load and needs to be reloaded if there is a change
  //1. If the user has gone into settings and changed the theme, it will be read
  //   first from localStorage
  //2. If it's not in localStorage, it will be read from the default theme.
  //3. If the theme is 'system' and the user system is set for dark, then return dark,
  //   else the UI will default to light
  return (
    <OriginalThemeProvider theme={themes[currentTheme]}>{React.Children.only(props.children)}</OriginalThemeProvider>
  );
};

import io from 'socket.io-client';

export const reconnectingSocket = socketUrl => {
  let socket;
  let isConnected = false;
  let reconnectOnClose = true;
  let stateChangeListeners = [];
  let listeners = {};

  function on(eventName: string, fn: (arg: any) => void) {
    const eventListeners = listeners[eventName] || [];
    eventListeners.push(fn);
    listeners[eventName] = eventListeners;
    console.log(eventName + ' on listeners[eventName] ' + listeners[eventName]);
    // @ts-ignore
  }

  function off(eventName: string, fn) {
    let eventListeners = listeners[eventName];
    if (eventListeners) {
      eventListeners = eventListeners.filter(l => l !== fn);
      listeners[eventName] = eventListeners;
    }
  }

  function onStateChange(fn) {
    // @ts-ignore
    stateChangeListeners.push(fn);
    return () => {
      stateChangeListeners = stateChangeListeners.filter(l => l !== fn);
    };
  }

  function start() {
    console.log(`websocket service, connecting to ${socketUrl}`);
    reconnectOnClose = true;
    // client = new WebSocket(socketUrl);
    socket = io(socketUrl);

    socket.on('connect', socket => {
      console.log('Socket IO connect');
      isConnected = true;
      // @ts-ignore
      stateChangeListeners.forEach(fn => fn(true));
    });

    socket.on('disconnect', () => {
      console.log('socket disconnect, should try reconnect');
      isConnected = false;
      // @ts-ignore
      stateChangeListeners.forEach(fn => fn(false));
      if (!reconnectOnClose) {
        console.log('ws closed by app');
        return;
      }
      // reconnectOnClose = false;
      //  close.call(client);
    });

    socket.on('connect_error', e => {
      isConnected = false;
      // console.error(e);
    });

    // client.onAny = event => {
    //   console.log("SOcket IO onAny");
    //   // @ts-ignore
    //   messageListeners.forEach(fn => fn(event.data));
    // };

    socket.onAny((event, ...data) => {
      try {
        console.log(`socket got ${event} ${JSON.stringify(data)}`);
      } catch {
        console.warn(`Error printing ws data`);
      }
      const eventListeners: any[] = listeners[event];
      console.log('Notifying event listeners, len: ' + eventListeners);
      if (eventListeners) {
        eventListeners.forEach(fn => fn(data));
      }
    });

    // client.on("collection-requests",function(event,data) {
    //   console.log(event);
    //   console.log(data);
    // });

    // client.onerror = e => console.error(e);

    socket.onclose = () => {
      isConnected = false;
      // @ts-ignore
      stateChangeListeners.forEach(fn => fn(false));

      if (!reconnectOnClose) {
        console.log('ws closed by app');
        socket.disconnect();
        return;
      }

      console.log('ws closed by server');
    };
  }

  start();

  return {
    on,
    off,
    onStateChange,
    close: () => socket.disconnect(),
    getClient: () => socket,
    isConnected: () => isConnected,
  };
};

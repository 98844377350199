import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

type Atr = { id: string; data: any };

const atrAdapter = createEntityAdapter<Atr>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: cr => cr.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.data.localeCompare(b.data),
});

export const atrSlice = createSlice({
  name: 'atr',
  initialState: atrAdapter.getInitialState({
    selectedId: null,
    showAll: true,
  }),
  reducers: {},
});

import { ThemeKeyType } from './slice/types';

/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)')?.matches : undefined;

export function saveTheme(theme: ThemeKeyType) {
  window.localStorage && localStorage.setItem('gunslingerConfig.THEME', theme);
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage['gunslingerConfig.THEME']
    ? (localStorage.getItem('gunslingerConfig.THEME') as ThemeKeyType) || null
    : null;
}

export const getTheme = (): ThemeKeyType | null => {
  //@ts-ignore
  const theme = window.gunslingerConfig?.THEME;

  // DEV NOTE: is there a better way to deal with aliased types? idk
  switch (theme) {
    case 'dark': {
      return 'dark';
    }
    case 'light': {
      return 'light';
    }
    default: {
      return 'system';
    }
  }
};

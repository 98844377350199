import React, { useContext, useState } from 'react';
import { AppComponent } from '../enums/sgs-components.enums';
import { LoadingState } from '../types/LoadingState';

export type State = {
  isMenuOpen: boolean;
  toggleMenu: any;
  stateChangeHandler: any;
  isSidebarOpen: boolean;
  toggleSidebar: any;
  showSidebar: any;
  activeApp: AppComponent;
  setActiveApp: any;
  loadingState: LoadingState | null;
  setLoadingState: any;
};
// make a new context
const AppContext = React.createContext<State | undefined>(undefined);

export const AppContextProvider = props => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [sidebarOpenState, setSidebarOpenState] = useState(false);
  const [activeApp, setActiveApp] = useState(AppComponent.None);
  const [loadingState, setLoadingState] = useState<LoadingState | null>(null);

  return (
    <AppContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: newState => setMenuOpenState(newState.isOpen),
        isSidebarOpen: sidebarOpenState,
        toggleSidebar: () => setSidebarOpenState(!sidebarOpenState),
        showSidebar: (show: boolean) => setSidebarOpenState(show),
        activeApp: activeApp,
        setActiveApp: (app: AppComponent) => setActiveApp(app),
        setLoadingState: setLoadingState,
        loadingState: loadingState,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppState = (): State | undefined => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('must be used within AppProvider');
  }
  return context;
};

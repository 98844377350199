/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { TaskingPage } from './pages/TaskingPage/Loadable';
import { useTranslation } from 'react-i18next';
import { Banner } from './components/Classification/Banner';
import styled from 'styled-components/macro';
import { useConfig } from './../services/Config';
import { ConfigurationPage } from './pages/Configuration/Loadable';
import { init } from '../services/ispy/ispy-service';

// document.addEventListener('DOMContentLoaded', function(event) {
//   console.log('DOMContentLoaded forf app, calling ispy init');
//   init();
//
// });

export function App() {
  const { i18n } = useTranslation();
  const { getConfig } = useConfig();
  const sec_banner = getConfig('SECURITY_CLASSIFICATION');
  const sec_color = getConfig('SECURITY_COLOR');
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Enhancing TCPED"
        defaultTitle="Shadow Gunslinger"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Shadow Gunslinger" />
        <meta name="version" content={process.env.REACT_APP_VERSION} />
      </Helmet>
      <TopWrapper>
        <Banner securityBanner={sec_banner} securityColor={sec_color} />
      </TopWrapper>
      <Routes>
        <Route path={process.env.PUBLIC_URL + '/'} element={<HomePage />} />
        <Route path={process.env.PUBLIC_URL + '/configuration'} element={<ConfigurationPage />} />
        <Route path={process.env.PUBLIC_URL + '/task'} element={<TaskingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
      <BottomWrapper>
        <Banner securityBanner={sec_banner} securityColor={sec_color} />
      </BottomWrapper>
    </BrowserRouter>
  );
}

const TopWrapper = styled.div`
  background: green;
  color: white;
  z-index: 10000;
  display: flex;
  width: 100%;
  top: 0;
  text-align: center;
  display: block;
  position: fixed;
`;

const BottomWrapper = styled.div`
  background: green;
  color: white;
  z-index: 10000;
  display: flex;
  width: 100%;
  bottom: 0;
  text-align: center;
  display: block;
  position: fixed;
`;

import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import { RequestStatus } from 'types/RequestStatus';
import { clearCollectionRequests, deletedState, dismissedState, setCollectionRequests } from './CollectionRequestSlice';

//DEV NOTES:

//  https://stackoverflow.com/questions/63413107/how-to-get-entities-as-an-array-in-a-class-component-when-using-createentityadap

// TODO: convert to entity adapter pattern
type IntelData = { id: string; opsBoxId: string; data: any };
type CollectionRequestIntelDataParam = { dismissed: boolean; deleted: boolean };

const intelDataAdapter = createEntityAdapter<IntelData>({
  selectId: inteldata => inteldata.id,
  // TODO: Keep the "all IDs" array sorted based updated time
  // TODO: 20231227 -- the data format is databahn oriented at this time. IF/WHEN we add other itnel data, the data "ideally"
  // should come back in some more normalized format, or at least common fields, like timestamps
  sortComparer: (a, b) => {
    try {
      const bTime = Date.parse(b.data.properties.sourceData.data['ReportTime']).valueOf();
      const aTime = Date.parse(a.data.properties.sourceData.data['ReportTime']).valueOf();
      const delta = bTime - aTime;
      if (isNaN(delta)) {
        return 0;
      }
      return delta;
    } catch {
      return 0;
    }
  },
});

const buildGeoJson = payload => {
  const data = Object.values(payload);
  const d2 = data.map((d: any) => d.data);

  const gjfc: any = {
    type: 'FeatureCollection',
    features: d2,
  };
  return gjfc;
};

const buildHistoricRetrievalData = (inteldata: IntelData[]) => {
  const data = {};
  inteldata.forEach(d => {
    data[d.opsBoxId] = { updated: new Date() };
  });
  return data;
};

const updateHistoricRetrievalData = (data, opsBoxId) => {
  if (opsBoxId) {
    data[opsBoxId] = { updated: new Date() };
  }
  return data;
};

const hasHistoricIntelData = (state, opsBoxId) => {
  if (state.historicRetrievalInfo) {
    return opsBoxId in state.historicRetrievalInfo;
  }
  return false;
};

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.selectedId = null;
  state.geojson = null;
  state.isSingleHistoricRetrievalOnly = true;
  state.historicRetrievalInfo = {};
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
};

export const intelDataSlice = createSlice({
  name: 'intelData',
  initialState: intelDataAdapter.getInitialState({
    status: RequestStatus.NOT_LOADED,
    selectedId: null,
    showAll: true,
    geojson: null as any,
    isSingleHistoricRetrievalOnly: true,
    historicRetrievalInfo: {},
    updated: new Date(),
  }),
  reducers: {
    intelDataAdded: intelDataAdapter.addOne,
    addIntelData: (state, action) => {
      // Or, call them as "mutating" helpers in a case reducer
      intelDataAdapter.addMany(state, action.payload);
      state.geojson = buildGeoJson(action.payload);
      state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.status = RequestStatus.SUCCESS;
      state.updated = new Date();
    },
    // addIntelData: (state, action: PayloadAction<any>) => {
    //   if (action.payload === null) return;
    //   intelDataAdapter.setAll(state, action.payload);
    //   // return {
    //   //   ...state,
    //   //   data: { type: state.data.type, features: [...state.data.features, ...action.payload] },
    //   // };
    // },
    setIntelData: (state, action: PayloadAction<IntelData[]>) => {
      intelDataAdapter.setAll(state, action.payload);
      state.geojson = buildGeoJson(action.payload);
      state.selectedId = null;
      state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.updated = new Date();
      state.status = RequestStatus.SUCCESS;
    },
    updateIntelData: (state, action: PayloadAction) => {
      //Not yet implemented
      //we need to be able to remove a specific datum and replace it with another
      // return state;
    },
    removeIntelData: state => {
      //Not yet implemented
      // return state;
    },
    clearIntelData: state => {
      clearState(state);
    },
    showAllData: state => {
      state.showAll = true;
    },
    hideAllData: state => {
      state.showAll = false;
    },
    setState: (state, action: PayloadAction<RequestStatus>) => {
      if (action.payload === null) return state;
      state.updated = new Date();
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllIntelData.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getAllIntelData.fulfilled, (state, { payload }) => {
      // if (payload) state.data.features = payload;
      if (payload) {
        intelDataAdapter.setAll(state, payload);
        // const data = Object.values(payload);
        // const d2 = data.map((d: any) => d.data);

        // const gjfc: any = {
        //   type: 'FeatureCollection',
        //   features: d2,
        // };
        state.geojson = buildGeoJson(payload);
        state.historicRetrievalInfo = buildHistoricRetrievalData(payload);
        state.updated = new Date();
        state.status = RequestStatus.SUCCESS;
        // const entities = payload.flatMap(entry => {
        //   return entry.payload.map(x => {
        //     return { id: x.data.properties.id, opsBoxId: entry.opsBoxId, data: x.data};
        //   });
        // });
        // intelDataAdapter.setAll(state, payload);
        // state.selectedId = null;
      }
    });
    builder.addCase(getAllIntelData.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(getIntelDataById.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getIntelDataById.fulfilled, (state, { payload }) => {
      if (payload) {
        console.log(`DP get intel data for fullfilled, ids pre update: ${state.ids}`);
        console.log(`DP get intel data for fullfilled, payload: ${JSON.stringify(payload)}`);
        // console.log('DP action ', action);
        if (payload.payload && payload.payload.length > 0) {
          // intelDataAdapter.upsertMany(state, payload.payload);
          intelDataAdapter.upsertMany(state, payload.payload);
          const x = payload.opsBoxId;
          console.log(`DP get intel data for ${x}`);
          console.log(`DP get intel data data ${payload.payload}`);
          state.selectedId = null;
          console.log(`DP get intel data ids ${JSON.stringify(state.ids)}`);
          console.log(`DP get intel data entities ${JSON.stringify(state.entities)}`);
          state.geojson = buildGeoJson(state.entities);
          state.historicRetrievalInfo = updateHistoricRetrievalData(state.historicRetrievalInfo, payload.opsBoxId);
          state.updated = new Date();
          state.status = RequestStatus.SUCCESS;
        } else {
          console.log(`DP get intel data for ${payload.opsBoxId} was empty`);
        }
      }
    });
    builder.addCase(getIntelDataById.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(clearCollectionRequests, (state, action) => {
      clearState(state);
    });
    builder.addCase(setCollectionRequests, (state, action) => {
      clearState(state);
    });

    //We're not doing anything yet with these reducers yet
    //builder.addCase(getAllIntelData.pending, state => { });
    //builder.addCase(getAllIntelData.rejected, state => { });
    //builder.addCase(getIntelDataById.pending, state => { });
    //builder.addCase(getIntelDataById.rejected, state => { });
  },
});

const getData = async (requestUrl: string) => {
  const client = axios.create();
  axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
  });
  const response = await client.get(requestUrl);
  return response.data;
};

/**
 * Thunk to retrieve all intel data
 */
export const getAllIntelData = createAsyncThunk('intelData/getAllIntelData', async (arg: any, thunkAPI) => {
  try {
    const response = await getData(arg);

    // const mr =response.flatMap(entry => {
    //   return entry.payload.map(p => p.data);
    // });
    const mr = response.flatMap(entry => {
      return entry.payload.map(x => {
        return { id: x.data.properties.id, opsBoxId: entry.opsBoxId, data: x.data };
      });
    });
    console.log(`getAllIntelData ${mr}`);
    return mr;
  } catch (ex) {
    //@ts-ignore
    console.error(`IntelData Error occurred calling '${arg}', code=${ex.code}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

/**
 * Thunk to retrieve updated intel by id
 */
export const getIntelDataById = createAsyncThunk('intelData/getIntelDataById', async (arg: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as any;
    if (state.intelData?.isSingleHistoricRetrievalOnly && hasHistoricIntelData(state.intelData, arg.id)) {
      console.log(`DP skipping retrieval, historic data exists for ${arg.id}`);
      return null;
    }
    const response = await getData(`${arg.url}/${arg.id}`);
    if (Array.isArray(response)) {
      const mr = response.flatMap(entry => {
        return entry.payload.map(x => {
          const id = x.data.properties?.id ? x.data.properties.id : uuidv4();
          return { id: id, opsBoxId: entry.opsBoxId, data: x.data };
        });
      });
      console.log(`getIntelDataById (array response) ${mr}`);
      const responsePayload = { opsBoxId: arg.id, payload: mr };
      return responsePayload;
    } else {
      // TODO 20240129 -- datab coming back doe NOT have a UUID for the data entity so generating here.
      // This should be fixed in the dbn service to return a UUID for the inteldata entity
      const mr = response.payload.map(x => {
        const id = x.data.properties?.id ? x.data.properties.id : uuidv4();
        return { id: id, opsBoxId: response.opsBoxId, data: x.data };
      });
      console.log(`getIntelDataById (non-array response) ${mr}`);
      const responsePayload = { opsBoxId: arg.id, payload: mr };
      console.log(`getIntelDataById responsePayload (non-array response) ${JSON.stringify(responsePayload)}`);
      return responsePayload;
    }
    // return response;
  } catch (ex) {
    //@ts-ignore
    console.error(`Error -getIntelDataById - calling '${arg}', code=${ex.code}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const {
  selectById: selectIntelDataById,
  selectIds: selectIntelDataIds,
  selectEntities: selectIntelDataEntities,
  selectAll: selectAllIntelData,
  selectTotal: selectTotalIntelData,
} = intelDataAdapter.getSelectors((state: any) => state.intelData);

export const selectIntelDataByCollectionRequestId = (id: string) =>
  createSelector([state => selectAllIntelData(state)], inteldata => inteldata.filter(data => data.opsBoxId === id));

/**
 * Returns the GeoJSON feature collection with an array of geoJSON features
 * representing the inteldata
 * Param is an object with dimissed, deleted
 * @param args {dismissed, deleted}
 * @returns GeoJSON
 */
export const selectIntelDataAsGeoJSONByCollectionRequestState = (args: CollectionRequestIntelDataParam) =>
  createSelector(
    [state => selectAllIntelData(state), dismissedState, deletedState],
    (inteldata, dismissed: any, deleted: any) => {
      const filteredIntelData = inteldata.filter(data => {
        let filter = false;
        if (!args.dismissed) {
          filter = dismissed.includes(data.opsBoxId);
        }
        if (!args.deleted) {
          filter = filter || deleted.includes(data.opsBoxId);
        }
        return !filter;
      });
      return buildGeoJson(filteredIntelData);
    },
  );

const showAllState = state => state.intelData.showAll;
export const selectIntelDataShowAll = createSelector([showAllState], x => x);

export const {
  addIntelData,
  setIntelData,
  updateIntelData,
  removeIntelData,
  clearIntelData,
  showAllData,
  hideAllData,
  setState,
} = intelDataSlice.actions;
export default intelDataSlice.reducer;

// DEV NOTES: using the react-use-websocket to start with here as I have hopes for the reconnect logic and all that jazz
// BUT the socket.io handling (which is what we are using on the backend) is less that ideal so willing to consider
// using something else or building our own socket.io effect

import React, { useCallback, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { reconnectingSocket } from '../services/websocket-service';
import { useConfig } from '../services/Config';

export type WebsocketState = {
  addSubscriber: any;
  unsubscribe: any;
};

const WebSocketContext = React.createContext<WebsocketState | undefined>(undefined);

let client;

export const WebSocketContextProvider = props => {
  console.log(`DP WebSocketContextProvider entry ${props}`);
  const { getConfig } = useConfig();
  const socket_url = getConfig('SGS_WEBSOCKET_URL');
  console.log(`Websocket url: ${socket_url}`);

  useEffect(() => {
    if (client) {
      client.close();
    }
    console.log(`Creating websocket connection for: ${socket_url}`);
    client = reconnectingSocket(socket_url);
  }, [socket_url]);

  useEffect(() => {
    return client.onStateChange(b => console.log(`state change connected: ${b}`));
  }, [socket_url]);

  const addSubscriber = useCallback(
    (event: string, cb: any) => {
      console.log(`adding subscribers for event ${event}`);
      client.on(event, cb);
      const subId: string = uuid();
      return subId;
    },
    [socket_url],
  );

  const unsubscribe = useCallback(
    (event: string, cb: any) => {
      console.log(`unsubscribe for event ${event}`);
      client.off(event, cb);
    },
    [socket_url],
  );

  const contextValue = {
    addSubscriber,
    unsubscribe,
  };
  return <WebSocketContext.Provider value={contextValue}>{props.children}</WebSocketContext.Provider>;
};

export const useWebsocketContextState = (): WebsocketState | undefined => {
  // const context = useRef(useContext(ImageryDataContext));
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('must be used within WebSocketContextProvider');
  }
  return context;
};
